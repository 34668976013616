import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { useSettingsStore } from '@/stores/settingsStore';
import { ScrollArea } from '@/components/ui/scroll-area';
import { SearchResultItem } from './search-result-item';
import { AttendeeSearchResult } from '../types/attendee-search-result';
import { useAttendeeFilterResult } from '../api/get-attendee-fllter-result';
import { Skeleton } from '@/components/ui/skeleton';
import { getLabelValue } from '@/utils/getLabelValue';
import { Search } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

interface FilterByStatDialogProps {
  children: React.ReactNode;
  status: 'notArrived' | 'arrived' | 'exited' | 'booked' | 'onsite';
}

export function AttendeeFilterDialog({ children, status }: FilterByStatDialogProps) {
  const [searchKey, setSearchKey] = useState('');
  const [isOpened, setIsOpened] = useState(false);

  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const breakoutId = useSettingsStore(state => state.breakoutId) ?? null;

  const { data: searchResult = [], isLoading } = useAttendeeFilterResult({
    status,
    eventId,
    breakoutId,
    config: {
      enabled: isOpened && navigator.onLine,
    },
  });

  const filteredSearchResult =
    searchKey?.length > 0
      ? searchResult
          .filter(item => {
            // Convert searchKey to lowercase and split into words
            let searchSplit = searchKey.toLowerCase().split(' ');

            // Check if searchResult matches any word in searchSplit on specific fields
            return searchSplit.some(word => {
              return (
                item.firstName.toLowerCase().includes(word) ||
                item.lastName.toLowerCase().includes(word) ||
                (item?.companyName?.toLowerCase()?.includes(word) ?? false) ||
                item.email.toLowerCase().includes(word) ||
                item.badgeNo.toLowerCase().includes(word) ||
                item.orderId.toString().toLowerCase().includes(word)
              );
            });
          })
          .slice(0, 10) // Use slice to avoid modifying the original array
          .map(item => {
            let score = 0;

            // Combine fields to form the searchParams string
            const searchParams = `${item.firstName} ${item.lastName} ${item.email} ${item?.companyName}`.toLowerCase();

            if (searchKey?.length === 0) {
              return item; // If searchKey is empty, return the item without score calculation
            }

            // Split searchKey into words
            const searchSplit = searchKey.toLowerCase().split(' ');

            // Iterate over searchSplit and calculate the score
            searchSplit.forEach(s => {
              if (item.orderId.toString() === s || item.badgeNo === s) {
                score += 10; // High-score match
                return; // Stop further checks if a high-score match is found
              } else if (searchParams.includes(s.toLowerCase())) {
                score += 1; // Lower-score match
              }
            });

            // Add the searchScore to the item
            item.searchScore = score;
            return item;
          })
          .filter(item => item.searchScore > 0) // Only keep items with a non-zero searchScore
          .sort((a, b) => b.searchScore - a.searchScore) // Sort by searchScore in descending order
      : searchResult.slice(0, 50); // Use slice to limit the number of results when no searchKey is provided

  // Get the max searchScore from the filtered results
  const maxSearchScore = filteredSearchResult.reduce((max, item) => (item.searchScore > max ? item.searchScore : max), 0);

  return (
    <Dialog
      onOpenChange={isOpen => {
        setIsOpened(isOpen);
      }}
    >
      <Tooltip>
        <TooltipTrigger className="w-full">
          <DialogTrigger className="w-full" onClick={() => setIsOpened(true)}>
            {children}
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>
          <p>{getLabelValue('FilterByStatTooltip')}</p>
        </TooltipContent>
      </Tooltip>

      <DialogContent className="max-w-4xl w-full bg-card">
        <DialogHeader>
          <DialogTitle>{getLabelValue(status + 'DialogTitle')}</DialogTitle>
        </DialogHeader>
        <div className="relative">
          <Search className="absolute top-1/2 left-3 -translate-y-1/2 w-4 h-4 text-muted-foreground" />
          <Input className="pl-8" onChange={event => setSearchKey(event.target.value)} value={searchKey} />
        </div>

        <ScrollArea className="max-h-[calc(80vh)] min-h-[calc(80vh)]">
          <div className="space-y-2 w-[calc(100%_-_20px)]">
            {isLoading ? (
              <>
                <Skeleton className="w-full h-[65px]" />
                <Skeleton className="w-full h-[65px]" />
                <Skeleton className="w-full h-[65px]" />
                <Skeleton className="w-full h-[65px]" />
                <Skeleton className="w-full h-[65px]" />
              </>
            ) : (
              filteredSearchResult
                ?.filter((searchResult: AttendeeSearchResult) => searchResult.searchScore === maxSearchScore)
                ?.map((searchResult: AttendeeSearchResult) => <SearchResultItem key={searchResult.badgeNo} searchResult={searchResult} />)
            )}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
