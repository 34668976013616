import { RouteObject, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PassingView } from '@/features/desktop/routes/passing-view';
import { Checkin } from '@/features/mobile/components/checkin';
import { useMe } from '@/features/auth/api/get-me';
import { useSettingsStore } from '@/stores/settingsStore';
import storage from '@/utils/storage';
import { LoginForm } from '@/features/auth/components/login-form';
import { useOnlineStatus } from '@/hooks/use-online-status';
import { PassagePageSkeleton } from '@/features/desktop/components/passage-page-skeleton';
import useEmbededAuth from '@/hooks/use-embeded-auth';

export const protectedRoutes: RouteObject[] = [
  { path: '/passing-view', element: <PassingView /> },
  {
    path: '/',
    element: <Checkin />,
  },
];

const publicRoutes: RouteObject[] = [
  {
    path: '*',
    element: <LoginForm />,
  },
];

export const AppRoutes = () => {
  const currentEventId = useSettingsStore(state => state.eventId);
  const setCurrentEventId = useSettingsStore(state => state.setEventId);
  const setBreakoutId = useSettingsStore(state => state.setBreakoutId);

  //TODO handle emeded auth
  //TODO handle mobile and desktop routes
  const isOnline = useOnlineStatus();

  // The page is embedded (probably from onsite in Jirango)
  const isEmbedded = window.self !== window.top;

  const isAutenticated = useEmbededAuth(import.meta.env.MODE === 'debug' ? 'https://localhost:44331' : 'https://jirango.com', isEmbedded);

  const { data: user, isFetched } = useMe({
    config: {
      enabled: isEmbedded ? isAutenticated : !!storage.getToken() && isOnline,
      refetchInterval: 1000 * 60 * 5, // 5 minutes,
      onSuccess: data => {
        if (data?.defaultEventId && !currentEventId) {
          setCurrentEventId(data.defaultEventId);
          setBreakoutId(data.defaultEventId);
        }

        if (!storage.getDeviceId()) {
          storage.setDeviceId();
        }
      },
    },
  });

  let routes = publicRoutes;

  if ((!isFetched && storage.getToken()) || (isEmbedded && !user)) {
    return <PassagePageSkeleton />;
  }

  if (isFetched && user && storage.getToken()) {
    routes = protectedRoutes;
  }

  const router = createBrowserRouter([...routes]);

  return <RouterProvider router={router} />;
};
