import { queryClient } from '@/lib/react-query';
import storage from '@/utils/storage';
import { useEffect, useState } from 'react';

function useEmbededAuth(expectedOrigin: string = 'https://localhost:44331', enabled: boolean = true) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    // Handler for the postMessage event
    const handleMessage = (event: any) => {
      console.log('from origin', event.origin);
      console.log('expected origin', expectedOrigin);

      // Verify that the message is from the expected origin
      if (event.origin !== expectedOrigin) return;

      console.log('message data', event.data);

      // Extract the token from the message data
      const token = event.data?.token;

      if (token) {
        storage.setToken(token);
        setIsAuthenticated(true);
        queryClient.invalidateQueries('me');
      }
    };

    if (!enabled) return;
    // Add the message event listener
    window.addEventListener('message', handleMessage, false);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('message', handleMessage, false);
  }, [expectedOrigin, enabled]);

  return isAuthenticated;
}

export default useEmbededAuth;
