import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { getLabelValue } from '@/utils/getLabelValue';
import { ChevronsDownUp, ChevronsUpDownIcon, LogIn, LogOut, MapPinned, UserCheck, UserRoundX } from 'lucide-react';
import { useOnsiteStats } from '../api/get-onsite-stats';
import { useSettingsStore } from '@/stores/settingsStore';
import { Button } from '@/components/ui/button';
import { cn } from '@/utils';
import { AttendeeFilterDialog } from './attendee-filter-dialog';

export function OverviewCards() {
  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const breakoutId = useSettingsStore(state => state.breakoutId) ?? 0;
  const expandedStats = useSettingsStore(state => state.expandedStats);

  const setExpandedStats = useSettingsStore(state => state.setExpandedStats);

  const { data: stats } = useOnsiteStats({
    eventId,
    breakoutId: breakoutId,
    config: {
      enabled: eventId > 0,
    },
  });

  const booked = stats?.booked ?? 0;
  const notArrived = stats?.notArrived ?? 0;
  const arrived = Object.keys(stats?.arrived ?? {}).length ?? 0;
  const checkouts = Object.keys(stats?.exit ?? {}).length ?? 0;
  const onsite = Object.keys(stats?.onsite ?? {}).length ?? 0;

  return (
    <div className="flex gap-4 lg:grid-cols-5 items-center p-6 relative mx-auto  max-w-7xl">
      <Button variant="ghost" className="p-1 h-6" onClick={() => setExpandedStats(!expandedStats)}>
        {expandedStats ? <ChevronsDownUp className="h-4 w-4" /> : <ChevronsUpDownIcon className="h-4 w-4 transform rotate-180" />}
      </Button>
      <AttendeeFilterDialog status="booked">
        <Card className="w-full  bg-card cursor-pointer">
          <CardHeader className={cn('flex flex-row items-center space-y-0 pb-2 justify-between', expandedStats ? 'pb-2  ' : 'p-4  gap-4 ')}>
            <div className="flex items-center gap-2">
              <div className={cn('text-3xl font-bold', expandedStats ? 'text-3xl' : 'text-xl')} hidden={expandedStats}>
                {booked}
              </div>
              <CardTitle className="text-md font-medium">{getLabelValue('Booked')}</CardTitle>
            </div>
            <UserCheck
              className={cn(
                ` text-sky-500 bg-sky-100
          dark:bg-sky-500 dark:text-white
          h-8 w-8 p-2 rounded-full`,
                expandedStats ? 'h-8 w-8 p-2 rounded-full' : 'h-6 w-6 p-1.5 rounded-full'
              )}
            />
          </CardHeader>
          <CardContent hidden={!expandedStats}>
            <div className="text-3xl font-bold text-left">{booked}</div>
          </CardContent>
        </Card>
      </AttendeeFilterDialog>
      <AttendeeFilterDialog status="arrived">
        <Card className="w-full  bg-card">
          <CardHeader className={cn('flex flex-row items-center justify-between space-y-0 pb-2', expandedStats ? 'pb-2  ' : 'p-4  gap-4 ')}>
            <div className="flex items-center gap-2">
              <div className={cn('text-3xl font-bold', expandedStats ? 'text-3xl' : 'text-xl')} hidden={expandedStats}>
                {arrived}
              </div>
              <CardTitle className="text-md font-medium">{getLabelValue('Arrived')}</CardTitle>
            </div>
            <LogIn
              className={cn(
                ` text-emerald-500 bg-emerald-100
          dark:bg-emerald-500 dark:text-white
          h-8 w-8 p-2 rounded-full`,
                expandedStats ? 'h-8 w-8 p-2 rounded-full' : 'h-6 w-6 p-1.5 rounded-full'
              )}
            />
          </CardHeader>
          <CardContent hidden={!expandedStats}>
            <div className="text-3xl font-bold text-left">{arrived}</div>
          </CardContent>
        </Card>
      </AttendeeFilterDialog>
      {checkouts > 0 && (
        <AttendeeFilterDialog status="exited">
          <Card className="w-full bg-card">
            <CardHeader className={cn('flex flex-row items-center justify-between space-y-0 pb-2', expandedStats ? 'pb-2  ' : 'p-4  gap-4 ')}>
              <div className="flex items-center gap-2">
                <div className={cn('text-3xl font-bold', expandedStats ? 'text-3xl' : 'text-xl')} hidden={expandedStats}>
                  {checkouts}
                </div>
                <CardTitle className="text-md font-medium">{getLabelValue('Checkouts')}</CardTitle>
              </div>
              <LogOut
                className={cn(
                  ` text-orange-500 bg-orange-100
          dark:bg-orange-500 dark:text-white
          h-8 w-8 p-2 rounded-full`,
                  expandedStats ? 'h-8 w-8 p-2 rounded-full' : 'h-6 w-6 p-1.5 rounded-full'
                )}
              />
            </CardHeader>
            <CardContent hidden={!expandedStats}>
              <div className="text-3xl font-bold text-left">{checkouts}</div>
            </CardContent>
          </Card>
        </AttendeeFilterDialog>
      )}

      <AttendeeFilterDialog status="notArrived">
        <Card className="w-full  bg-card">
          <CardHeader className={cn('flex flex-row items-center justify-between space-y-0 pb-2', expandedStats ? 'pb-2  ' : 'p-4  gap-4 ')}>
            <div className="flex items-center gap-2">
              <div className={cn('text-3xl font-bold', expandedStats ? 'text-3xl' : 'text-xl')} hidden={expandedStats}>
                {notArrived}
              </div>
              <CardTitle className="text-md font-medium">{getLabelValue('NotArrived')}</CardTitle>
            </div>
            <UserRoundX
              className={cn(
                ` text-red-500 bg-red-100
            dark:bg-red-500 dark:text-white
                      
          h-8 w-8 p-2 rounded-full`,
                expandedStats ? 'h-8 w-8 p-2 rounded-full' : 'h-6 w-6 p-1.5 rounded-full'
              )}
            />
          </CardHeader>
          <CardContent hidden={!expandedStats}>
            <div className="text-3xl font-bold text-left">{notArrived}</div>
          </CardContent>
        </Card>
      </AttendeeFilterDialog>

      {checkouts > 0 && (
        <AttendeeFilterDialog status="onsite">
          <Card className="w-full  bg-card">
            <CardHeader className={cn('flex flex-row items-center justify-between space-y-0 pb-2', expandedStats ? 'pb-2  ' : 'p-4  gap-4 ')}>
              <div className="flex items-center gap-2">
                <div className={cn('text-3xl font-bold', expandedStats ? 'text-3xl' : 'text-xl')} hidden={expandedStats}>
                  {onsite}
                </div>
                <CardTitle className="text-md font-medium">{getLabelValue('Onsite')}</CardTitle>
              </div>
              <MapPinned
                className={cn(
                  ` text-purple-500
            dark:bg-purple-500 dark:text-white
          bg-purple-100 h-8 w-8 p-2 rounded-full`,
                  expandedStats ? 'h-8 w-8 p-2 rounded-full' : 'h-6 w-6 p-1.5 rounded-full'
                )}
              />
            </CardHeader>
            <CardContent hidden={!expandedStats}>
              <div className="text-3xl font-bold text-left">{onsite}</div>
            </CardContent>
          </Card>
        </AttendeeFilterDialog>
      )}
    </div>
  );
}
