import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { useToast } from '@/components/ui/use-toast';
import { getLabelValue } from '@/utils/getLabelValue';

export const deleteOnisteStatistics = ({ eventId }: { eventId: number }) => {
  return axios.delete(`${eventId}/onsite`);
};

type UseDeleteOnisteStatistics = {
  eventId: number;
  config?: MutationConfig<typeof deleteOnisteStatistics>;
};

export const useDeleteOnsiteStatistics = ({ config, eventId }: UseDeleteOnisteStatistics) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, ___: any) => {
      toast({
        variant: 'success',
        title: getLabelValue('SomethingWentWrong'),
      });
    },
    onSuccess: () => {

      queryClient.invalidateQueries(['onsite_stats', `${eventId}`]);
      queryClient.invalidateQueries(['onsite_stats', `${eventId}_${eventId}`]);

      toast({
        variant: 'success',
        title: getLabelValue('OnsiteStatisticsDeleted'),
      });
    },
    ...config,
    mutationFn: ({ eventId }) => deleteOnisteStatistics({ eventId }),
  });
};
