import { useSettingsStore } from '@/stores/settingsStore';
import { useEvents } from '@/api/useEvents';
import { Skeleton } from '@/components/ui/skeleton';
import { SettingsDialog } from './settings-dialog';
import { EntryDirectionDropdown } from './entry-direction-dropdown';
import { PrinterDropdown } from './printer-dropwdown';
import { EntranceDropdown } from './entrance-dropdown';
import { useOnlineStatus } from '@/hooks/use-online-status';
import { Badge } from '@/components/ui/badge';
import { getLabelValue } from '@/utils/getLabelValue';
import { Wifi, WifiOff } from 'lucide-react';

export function Header() {
  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const connected = useSettingsStore(state => state.connected);

  const {
    data: events = [],
    isLoading,
    isFetched,
  } = useEvents({
    eventId,
    config: {
      enabled: false,
    },
  });

  const selectedEvent = events?.find(event => event.eventId === eventId);
  const eventName = selectedEvent?.name;
  const isOnline = useOnlineStatus();

  return (
    <header className="py-3 px-6  gap-4 items-center w-full justify-between grid grid-cols-2 max-w-7xl mx-auto">
      {isFetched && !isLoading ? (
        <h1 className="text-lg font-semibold flex gap-2 items-center">
          <span className=""> {eventName}</span>/
          <EntranceDropdown />
        </h1>
      ) : (
        <div className="flex items-center gap-2">
          <Skeleton className="w-64 h-8 " />
          /
          <Skeleton className="w-32 h-8 " />
        </div>
      )}
      <div className="flex gap-x-2 h-10 justify-end items-center">
        <EntryDirectionDropdown />
        <PrinterDropdown />
        <SettingsDialog />
        {connected && isOnline ? (
          <Badge className="bg-emerald-500 h-full rounded-md">
            <Wifi className="h-5 w-5 " />
          </Badge>
        ) : (
          <Badge className="bg-red-500 h-full rounded-md">
            <WifiOff className="h-5 w-5" />
          </Badge>
        )}
      </div>
    </header>
  );
}
