import { Button } from '@/components/ui/button';
import { DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuGroup, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useSettingsStore } from '@/stores/settingsStore';
import { DropdownMenu } from '@radix-ui/react-dropdown-menu';
import { AlertCircle, PrinterIcon, WifiOff } from 'lucide-react';
import { getLabelValue } from '@/utils/getLabelValue';
import { SignalRContext } from '@/providers/SignalRProvider';
import { useToast } from '@/components/ui/use-toast';
import { Printer } from '@/types';

export function PrinterDropdown() {
  const eventId = useSettingsStore(state => state.eventId) ?? 0;

  const { toast } = useToast();

  const selectedPrinter = useSettingsStore(state => state.printer);
  const printers = useSettingsStore(state => state.printers);

  const setPrinter = useSettingsStore(state => state.setPrinter);
  const setPrinters = useSettingsStore(state => state.setPrinters);

  const onPrinterConnected = async (printer: Printer) => {
    if (printer.clientGuid === selectedPrinter?.clientGuid) {
      setPrinter({ ...printer, online: true });
      setPrinters(printers.map(p => (p.name === selectedPrinter.name ? { ...p, online: true } : p)));
      toast({ title: getLabelValue('PrinterConnected'), variant: 'success' });

      return;
    }

    setPrinters([...printers.map(p => (p.clientGuid === printer.clientGuid ? printer : { ...printer, online: true }))]);
  };

  const onPrinterDisconnected = async (id: string) => {
    if (selectedPrinter?.clientGuid === id) {
      toast({ title: getLabelValue('PrinterDisconnected'), variant: 'destructive' });
      setPrinter({ ...selectedPrinter, online: false });
      setPrinters(printers.map(printer => (printer.clientGuid === id ? { ...printer, online: false } : printer)));
    } else {
      setPrinters(printers.filter(printer => printer.clientGuid !== id));
    }
  };

  const onGetActivePrintClients = async (connectedPrinters: Printer[]) => {
    const modifiedPrinters = [...connectedPrinters.map(printer => ({ ...printer, online: true }))];

    if (selectedPrinter && !modifiedPrinters.some(printer => printer.clientGuid === selectedPrinter.clientGuid)) {
      modifiedPrinters.push({ ...selectedPrinter, online: false });
    }

    setPrinters(modifiedPrinters);
  };

  SignalRContext.useSignalREffect('OnPrinterConnected', onPrinterConnected, [selectedPrinter]);
  SignalRContext.useSignalREffect('OnPrinterDisconnected', onPrinterDisconnected, [selectedPrinter]);
  SignalRContext.useSignalREffect('OnGetActivePrintClients', onGetActivePrintClients, [printers]);

  // useEffect(() => {
  //   if (SignalRContext.connection?.state !== 'Connected') return;
  //   (async () => {
  //     try {
  //       await SignalRContext.invoke('GetActivePrintClients', eventId);
  //     } catch (error) {

  //       console.log(error);

  //       toast({ title:  JSON.stringify(error), variant: 'destructive' });
  //     }
  //   })();
  // }, [SignalRContext.connection?.state, eventId]);

  const fetchPrinters = async () => {
    try {
      await SignalRContext.invoke('GetActivePrintClients', eventId);
    } catch (error) {
      toast({ title: getLabelValue('FailedToFetchPrinters'), variant: 'destructive' });
    }
  };

  const isConnected = printers?.some(printer => printer.clientGuid === selectedPrinter?.clientGuid);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant="outline" onClick={fetchPrinters}>
          {isConnected && selectedPrinter?.online === true ? <PrinterIcon className="h-4 w-4 mr-1" /> : <AlertCircle className="h-4 w-4 mr-1 mt-0.5 text-red-500" />}
          {isConnected ? selectedPrinter?.name : getLabelValue('NoPrinterSelected')}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-64">
        <DropdownMenuLabel className="flex items-center">{getLabelValue('SelectPrinter')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {printers?.length > 0 ? (
          printers.map(printer => (
            <DropdownMenuCheckboxItem
              className="flex items-center justify-between"
              checked={printer.clientGuid === selectedPrinter?.clientGuid}
              onClick={() => setPrinter(printer.clientGuid === selectedPrinter?.clientGuid ? null : printer)}
            >
              {printer.name}
              {!printer.online && <WifiOff className="h-4 w-4 ml-1  text-red-500" />}
            </DropdownMenuCheckboxItem>
          ))
        ) : (
          <DropdownMenuLabel className="font-normal">{getLabelValue('NoPrintersFound')}</DropdownMenuLabel>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
