import { Card } from '@/components/ui/card';
import { AttendeeSearchResult } from '../types/attendee-search-result';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Briefcase, Languages, LogIn, LogOut, Mail, Search } from 'lucide-react';
import { getLabelValue } from '@/utils/getLabelValue';
import { useSettingsStore } from '@/stores/settingsStore';
import { AttendeeDetailsDialog } from './attendee-details-dialog';
import { useOnsiteStats } from '../api/get-onsite-stats';
import { Direction } from '../types/direction';
import { Spinner } from '@/components/ui/spinner';

export function SearchResultItem({
  searchResult,
  onPassing,
  proccessingBadgeNo,
}: {
  searchResult: AttendeeSearchResult;
  onPassing?: (badgeNo: string) => void;
  proccessingBadgeNo: string | null;
}) {
  const direction = useSettingsStore(state => state.direction);
  const eventId = useSettingsStore(state => state.eventId) ?? 0;
  const breakoutId = useSettingsStore(state => state.breakoutId) ?? null;
  const languageCode = useSettingsStore(state => state.languageCode) ?? 'swe';

  const { data: stats } = useOnsiteStats({
    eventId,
    breakoutId: breakoutId,
    config: {
      enabled: false,
    },
  });

  const isOnsite = stats?.onsite?.[searchResult.badgeNo];

  return (
    <Card className="py-2 px-4 shadow-sm dark:shadow-xl  text-xl  border justify-between">
      <div className="flex justify-between items-center">
        <div className="flex gap-4 items-center">
          <Avatar className="w-[60px] h-[60px]">
            <AvatarImage src={searchResult?.profileImage} alt="@shadcn" />
            <AvatarFallback className="uppercase text-xl">
              {searchResult.firstName[0]}
              {searchResult.lastName[0]}
            </AvatarFallback>
          </Avatar>
          <div className="space-y-2">
            <div>
              <div className="font-semibold flex gap-4 items-center">
                <span className="truncate max-w-64">
                  <a
                    href={`https://jirango.com/attendee/edit/${searchResult.orderId}?eventId=${eventId}&languageCode=${languageCode}`}
                    target="_blank"
                    className="underline"
                    rel="noreferrer"
                  >
                    {searchResult.firstName} {searchResult.lastName}
                  </a>
                </span>

                {isOnsite && (
                  <div className="flex h-full items-center gap-x-3 text-sm">
                    <div className="relative flex justify-center items-center">
                      <span className="bg-emerald-500 w-2 h-2 rounded-full animate-ping absolute" />
                      <span className="bg-emerald-500 w-2 h-2 rounded-full absolute " />
                    </div>
                    {getLabelValue('Onsite')}
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start  text-muted-foreground">
                {searchResult.companyName && (
                  <div className="flex items-center">
                    <Briefcase className="h-4 w-4 mr-1" />
                    <span className="truncate">{searchResult.companyName}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex  gap-2">
          {onPassing && (
            <Button size="default" className="text-lg" disabled={proccessingBadgeNo === searchResult.badgeNo} variant="outline" onClick={() => onPassing(searchResult.badgeNo)}>
              {proccessingBadgeNo === searchResult.badgeNo ? (
                <Spinner className="h-4 w-4 mr-1 text-muted-foreground" />
              ) : direction === Direction.IN ? (
                <LogIn className="h-4 w-4 mr-1 text-emerald-500" />
              ) : (
                <LogOut className="h-4 w-4 mr-1 text-orange-500" />
              )}
              {direction === Direction.IN ? getLabelValue('CheckInAction') : getLabelValue('CheckOutAction')}
            </Button>
          )}

          {/* <AttendeeDetailsDialog attendee={searchResult} /> */}
        </div>
      </div>
    </Card>
  );
}
